<template>
  <v-list>
    <ListItem
      v-for="(item,i) in enclst.items"
      :key="i"
      :index="i"
      :title="item.title"
      @selected="onSelected"
    />
  </v-list>
</template>

<script>
import ListItem from "@/components/ListItem.vue"
//import { EncLst } from "enclst-core-js"
export default {
  components:{ListItem},
  props: {
    enclst: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    onSelected(index){
      // get path
      let path = this.enclst.items[index].value.first()

      if (this.enclst.isEnclst(path)){
        // path is for enclst
        if (this.enclst.filepath == ""){
          this.$router.push({ name: 'List', params: { url: path } })
        } else {
          const nextURL = this.enclst.nextFilePath(path)
          this.$router.push({ name: 'List', params: { url: nextURL } })
        }
      } else if (this.enclst.isURL(path)) {
        // path is for URL
        console.log("open")
        window.location.href = path
        //window.open(url, '_blank')
      }

    },
  },
}
</script>

