<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
    class="mx-auto"
    max-width="500"
  >
    <div>
    <v-toolbar
      color="#f5f5f5"
    >
      <v-toolbar-title class="grey--text mx-auto">Edit</v-toolbar-title>
    </v-toolbar>

    <EditHome
      @save="save"
      @cancel="close"
      ref="edithome"
    />
    </div>
  </v-dialog>
</template>

<script>
import EditHome from '@/components/EditHome.vue'

export default {
  components: {
    EditHome,
  },
//  mixins: [list],
  props: {
    items: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    //
    dialog: false,
  }),
  methods: {
    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(){
      /** open dialog */
      this.dialog = true
      this.$refs.edithome.refresh()
    },
    openWithAddItem(url, title){
      this.open()
      setTimeout(() => {
        this.openAddItemDialogAddItem(url, title)
//        this.$refs.addItemDialog.focus();
      }, 200)
    },
    /**
     * Handler of left hand side FAB
     */
    close(){
      this.dialog = false
    },
    save(){
      this.$emit("save")
      this.close()
    }
  }
}
</script>