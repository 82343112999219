<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <EditDialog
      ref="editDialog"
    />
    <v-card-title>Edit Home Links</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item
          @click="edit"
        >
          <v-list-item-content
          >
            <v-list-item-title>Edit Home Links</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="reset"
        >
          <v-list-item-content
          >
            <v-list-item-title>Reset Home Links to default</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import EditDialog from '@/dialogs/Edit.vue'
export default {
  components:{EditDialog},
  methods: {
    edit(){
      this.$refs.editDialog.open()
    },
    reset(){
      localStorage.removeItem("homeList")
    },
  }
}
</script>