<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <EditItemDialog
      :items="enclst.items"
      @save="saveItem"
      ref="editItemDialog"
    />
    <AddItemDialog
      @added="itemAdded"
      ref="addItemDialog"
    />
    <v-card-title>Edit Home Links</v-card-title>
    <v-card-text>
      <v-list>
        <draggable v-model="enclst.items" draggable=".item">
          <v-list-item
            v-for="(item,i) in enclst.items"
            :key="i"
            @click="selected(i)"
            class="item"
          >
            <v-list-item-content
            >
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon
                @click.stop="deleteItem(i)"
              >
                <v-icon color="grey lighten-1">mdi-delete-forever</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </draggable>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn
      text
      @click="cancel"
      >
        Cancel
      </v-btn>

      <v-spacer/>

      <v-btn
      text
      @click="openAddItemDialog"
      >
        Add
      </v-btn>
      
      <v-spacer/>

      <v-btn
      text
      @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { readHome } from '@/libs/readHome'
import draggable from 'vuedraggable'
import EditItemDialog from '@/dialogs/EditItem.vue'
import AddItemDialog from '@/dialogs/Add.vue'

export default {
  components: {
    draggable,
    EditItemDialog,
    AddItemDialog
  },
//  mixins: [list],
  data: () => ({
    //
    dialog: false,
    enclst: readHome(),
  }),
  methods: {
    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(){
      /** open dialog */
      this.dialog = true
    },
    openWithAddItem(url, title){
      this.open()
      setTimeout(() => {
        this.openAddItemDialogAddItem(url, title)
//        this.$refs.addItemDialog.focus();
      }, 200)
    },
    refresh(){
      this.enclst = readHome()
    },
    /**
     * Handler of left hand side FAB
     */
    close(){
      this.dialog = false
    },
    deleteItem(i){
      if (confirm("Do you really want to delete this?")){
        this.enclst.items.splice(i,1)
      }
    },
    save(){
      console.log("this.enclst.",this.enclst)
      localStorage.homeList = this.enclst.serialize()
      this.$emit('save', this.enclst.items)
      this.close()
    },
    cancel(){
      this.$emit('cancel')
      this.close()
    },

    //
    // for EditItemDialog
    //
    selected(i){
      // open EditItemDialog
      this.$refs.editItemDialog.open(i)
    },
    saveItem(index, item){
      console.log("index",index)
      console.log("item",item)
      this.items[index].url = item.url
      this.items[index].title = item.title
    },

    //
    // for AddItemDialog
    //
    openAddItemDialog(){
      this.$refs.addItemDialog.open()
    },
    openAddItemDialogAddItem(url, title){
      this.$refs.addItemDialog.openWithAddItem(url, title)
      this.$refs.addItemDialog.focus();
    },
    itemAdded(item){
      this.enclst.items.push(item)
    }
  }
}
</script>