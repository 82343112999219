<template>
  <v-list-item
    @click="$emit('selected', index)"
  >
    <v-list-item-content
    >
      <v-list-item-title v-text="title"></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: String,
    index: Number,
  },
  data: () => ({
    isContextMenuShown: false,
  }),
  methods:{
    openContextMenu(){
      this.isContextMenuShown = !this.isContextMenuShown
    }
  },
}
</script>