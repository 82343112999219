<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
  >
    <v-card>
      <v-card-text>
        <!-- title imput -->
        <v-text-field
          label="tilte"
          v-model="title"
        />
        <!-- url imput -->
        <v-text-field
          label="url"
          v-model="url"
        />
        <!-- switch if list -->
        <div class="d-flex justify-end">
          <v-switch
            v-model="isList"
            :label="`List?: ${isList.toString()}`"
            class="ml-auto"
          ></v-switch>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          text
          @click="cancel"
        >
          cancel
        </v-btn>

        <v-btn 
          text
          @click="save"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Item } from 'enclst-core-js'

export default {
  data () {
    return {
      dialog: false,
//      item: new Item(),
      title: "",
      url: "",
      isList: false,
    }
  },
  methods: {
    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(){
      /** open dialog */
      this.dialog = true
    },
    openWithAddItem(url, title){
      this.dialog = true
      this.url = url
      this.title = title
    },
    close(){
      this.dialog = false
    },

    /**
     * Handler of left hand side FAB
     */
    cancel(){
      this.close()
    },

    save(){
      let item = new Item()
      item.value.positionalValues.push(this.url)
      item.title = this.title
      this.$emit("added", item)
      this.close()
    }
  }
}
</script>