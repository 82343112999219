<template>
  <v-app>
    <Navbar :links="links" title="kero">
      <template v-slot:title>
        <Title/>
      </template>

      <template v-slot:menu>
        <v-spacer></v-spacer>
        <Menu/>
      </template>
    </Navbar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer>
      <v-col class="text-center grey--text">
        <span style="text-shadow: 2px 2px 2px #aaa; font-size: 100%;">© Aterier UEDA</span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import {Navbar} from 'vuetify-nav2'
import Title from '@/components/Navbar/Title'
import Menu from '@/components/Navbar/Menu'

export default {
  name: 'App',
  components: { Navbar, /*Login,*/ Title, Menu},
  data: () => ({
    //
    links: [
      { icon: 'home', text: 'Home', route: '/'},
      { icon: 'precision_manufacturing', text: 'Make Direct-Link', route: '/makedirectlink'},
      { icon: 'edit', text: 'Edit home', route: '/edithome'},
      { icon: 'menu_book', text: 'Document', route: '/docs'},
    ]
  }),
};
</script>
