<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
<!--    <AddDialog
      ref="addDialog"
    />-->
    <EditDialog
      @cancel="cancelEdit"
      @save="refresh"
      ref="editDialog"
    />
    <ExportDialog
      :enclst="enclst"
      ref="exportDialog"
    />
    <v-card-title>home</v-card-title>

    <v-card-text>
      <ShowList
        :enclst="enclst"
      />
    </v-card-text>

    <v-card-actions>
      <v-btn 
        text
        @click="exportList"
      >
        export
      </v-btn>
      <v-spacer/>
      <v-btn
        text
        @click="edit"
      >
        edit
      </v-btn>
    </v-card-actions>
  </v-card>  
</template>

<script>
import ShowList from "@/components/ShowList.vue"
import EditDialog from '@/dialogs/Edit.vue'
import ExportDialog from '@/dialogs/Export.vue'
import { EncLst } from 'enclst-core-js'
import { readHome } from '@/libs/readHome'

export default {
  components:{EditDialog, ShowList, ExportDialog},
  data: () => ({
    enclst: new EncLst(),
  }),

  methods: {
    edit(){
      this.$refs.editDialog.open()
    },
    refresh(){
      this.enclst = readHome()
      console.log("enclst", this.enclst)
    },
    exportList(){
      this.$refs.exportDialog.open()
    },
    cancelEdit(){
      this.refresh()
    }
  },
  mounted(){
    console.log("window", window)
    this.refresh()
  }
}
</script>