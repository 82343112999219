<template>
  <div class="home">
    <AddDialog
      ref="addDialog"
      @added="itemAdded"
    />
    <DirectLinkDialog
      :url="enclst.filepath"
      ref="directLinkDialog"
    />
    <v-card
      class="mx-auto"
      max-width="500"
      scrollable
    >
      <v-card-title>
        {{enclst.title}}
      </v-card-title>

      <v-card-text>
        <ShowList
          :enclst="enclst"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn 
          text
          @click="directLink"
        >
          Direct Link
        </v-btn>
        <v-spacer/>
        <v-btn 
          text
          @click="add"
        >
          add to home
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import ShowList from "@/components/ShowList.vue"
import AddDialog from '@/dialogs/Add.vue'
import DirectLinkDialog from '@/dialogs/DirectLink/Body.vue'
import directLinkMixin from '@/dialogs/DirectLink/mixin'
import { EncLst } from 'enclst-core-js'

export default {
  components: {AddDialog, /*EditDialog, */ShowList, DirectLinkDialog},
  mixins: [/*list, */directLinkMixin],

  data: () => ({
    enclst: new EncLst(), // blank enclist, will readURL() at mounted.
  }),
  methods: {
    add(){
      this.$refs.addDialog.openWithAddItem(this.enclst.filepath, this.enclst.title)
  },
    cancelEdit(){
    },
    itemAdded(item){
      const data = localStorage.homeList
      let enclst = new EncLst(data)
      enclst.items.push(item)
      localStorage.homeList = enclst.serialize()
    }

  },
  async mounted(){
    let url = this.$route.params.url
    this.enclst.readURL(url)
  },
  // https://abillyz.com/watanabe/studies/226
  watch: {
    $route () {
      location.reload()
    }
  },
}
</script>
